import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx50DayMaChartTemplate from '@/templates/crypto/xxx-50-day-moving-average-chart';

export default function Bitcoin50DayMaChart({ location }: GatsbyPageProps) {
  return (
    <Xxx50DayMaChartTemplate
      location={location}
      coinAbbreviation="BTC"
      coinFullName="Bitcoin"
      token={Token.BTCUSDT}
      articleId={ArticleList.BITCOIN_50_DAY_MOVING_AVERAGE_CHART}
    ></Xxx50DayMaChartTemplate>
  );
}
